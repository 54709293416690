const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  basename: '',
  defaultPath: '/dashboard/default',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  variable: [],
  // API_SERVER: 'https://backend-wkfu.onrender.com/api/v1/',
  API_SERVER: 'http://localhost:3000/api/v1/',
  // BASE_API: 'https://backend-wkfu.onrender.com',
  BASE_API: 'http://localhost:3000/api/v1/',
};

export default config;
