import { dashboard } from './dashboard';
import { utilities } from './utilities';
import { others } from './other';
import { pages } from './pages';
//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard, others, pages]
};

export default menuItems;
